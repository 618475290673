

.footer {
    background-color: #050606;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: calc(12px + 1vmin);
    color: grey;
    padding: 20px;
    min-height: 100px;
  }